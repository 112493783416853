(function () {
  const copyright = document.querySelector('#footer .copyright > span')
  const currentYear = new Date().getUTCFullYear()

  const updateYear = (el, year) => {
    el.innerText = year
  }
  updateYear(copyright, currentYear)

  document.addEventListener('DOMContentLoaded', function () {
    function navigation () {
      const pageHeader = document.getElementById('page__header')
      const pageNav = document.getElementById('page__nav')
      const navIcon = document.querySelector('.nav__menu--icon')

      window.addEventListener('scroll', function () {
        if (window.scrollY > 100) {
          pageHeader.classList.add('sticky')
        } else {
          pageHeader.classList.remove('sticky')
        }
      })

      function toggleNav () {
        navIcon.addEventListener('click', function () {
          this.classList.toggle('open')
          pageNav.classList.toggle('active')
          if (window.scrollY > 100) {
            pageHeader.classList.toggle('sticky')
          }
        })
      }
      toggleNav()
    }
    navigation()

    function scrollTop () {
      const scrollTopBtn = document.querySelector('.scroll__top')
      window.addEventListener('scroll', function () {
        if (window.scrollY > 1000) {
          scrollTopBtn.classList.add('active')
        } else {
          scrollTopBtn.classList.remove('active')
        }
      })

      scrollTopBtn.addEventListener('click', function () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
    }
    scrollTop()
  })
})()
